import { css, keyframes } from "@emotion/css";
import React from "react";

const loaderAnimation = keyframes`
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

const Loader = () => {
  return (
    <div
      className={css`
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        text-align: center;
        transform: translate(-50%, -50%);
        div {
          width: 20px;
          height: 20px;
          margin: 0 5px;
          background-color: #ffffff;

          border-radius: 100%;
          display: inline-block;
          animation: ${loaderAnimation} 1.4s infinite ease-in-out both;
        }
      `}
    >
      <div
        className={css`
          animation-delay: -0.32s !important;
        `}
      />
      <div
        className={css`
          animation-delay: -0.16s !important;
        `}
      />
      <div
        className={css`
          animation-delay: 0s !important;
        `}
      />
    </div>
  );
};

export default Loader;
