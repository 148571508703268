import React, { useState } from "react";

import "@fontsource/noto-sans";

import axios from "axios";
import CryptoJS from "crypto-js";
import { css, injectGlobal } from "@emotion/css";
import queryString, { ParsedQuery } from "query-string";
import { useMount } from "react-use";
import { useToasts } from "react-toast-notifications";

import ErrorIcon from "./images/error.png";
import Loader from "components/Loader";

interface EncryptedImageProps {
  contentType: string;
  image: string;
}

injectGlobal`
*{
  overscroll-behavior: none;
  overflow: hidden;
  -ms-overflow-style: none;

scrollbar-width: none;
}
*::-webkit-scrollbar {
display: none;
}


  html, body {
    background: black;
    margin: 0;
  }
  a{
    cursor: default;
  }
  body {
    font-family: "noto-san", sans-serif;
  }
`;

const { token, key }: ParsedQuery<string> = queryString.parse(
  window.location.search
);

const serverDomains = {
  core: ["api-core0.ricerobotics.com", "api-core1.ricerobotics.com"],
  core2: ["api2-core0.ricerobotics.com", "api2-core1.ricerobotics.com"],
}[process.env.REACT_APP_BUILD];

const App = () => {
  const { addToast } = useToasts();

  const [imageSource, setImageSource] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const isLinkValid = () => !token || !key;

  const handleError = (errorMessage: string) => {
    setIsError(true);
    addToast(errorMessage, { appearance: "error" });
  };

  const getImage = async (token: string): Promise<EncryptedImageProps> => {
    const { data, headers } = await axios.get(
      `https://${serverDomains[0]}/user-content`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "arraybuffer",
      }
    );

    return {
      image: Buffer.from(data).toString("base64"),
      contentType: headers["content-type"],
    };
  };

  const decodeKey = async (image: string, key: string) => {
    return CryptoJS.AES.decrypt(image, key).toString(CryptoJS.enc.Utf8);
  };

  useMount(async () => {
    if (isLinkValid()) {
      return handleError("Invalid link.");
    }

    try {
      const imageData = await getImage(token as string);
      const { image, contentType } = imageData;

      const imageString = await decodeKey(image, key as string);
      const imageBase64 = Buffer.from(imageString, "binary").toString("base64");

      const base64Response = await fetch(
        `data:${contentType};base64,${imageBase64}`
      );
      const blob = await base64Response.blob();

      setImageSource(URL.createObjectURL(blob));
      setIsLoading(false);
    } catch (err) {
      handleError("Oops! Something went wrong.");
      return;
    }
  });

  return (
    <div
      className={css`
        width: 100vw;
        height: 100vh;
        display: flex;
      `}
    >
      {isError && (
        <img
          className={css`
            max-width: 250px;
            width: 30vw;
            object-fit: contain;
            margin: auto;
          `}
          src={ErrorIcon}
          alt="error"
        />
      )}
      {isLoading && !isError && <Loader />}

      {!isError && !isLoading && (
        <a
          download="photo-booth"
          href={imageSource}
          onClick={(e) => e.preventDefault()}
          className={css`
            width: 100%;
            margin: auto;
            display: flex;
          `}
        >
          <img
            className={css`
              max-width: 100vw;
              max-height: 100vh;
              margin: auto;
            `}
            src={imageSource}
            alt=""
          />
        </a>
      )}
    </div>
  );
};

export default App;
